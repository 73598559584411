.content-holder {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 20px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: 2%;
    text-align: left;
    font-size: 19px;
}

.content-header {
    /* text-decoration: underline; */
    font-size: 27px;
}

a {
    color: rgb(120, 122, 146);
}