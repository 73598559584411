body {
    margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #282c34;
    font-family: 'League Spartan', sans-serif;
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100vh;
}

.App {
    margin-top: 5%;
    text-align: center;
    color: white;
    width: 70vw;
    /* height: 100vw; */
    
}

.main-app {
  /* border-style: solid;
  border-color: rgb(120, 122, 146);
  border-width: 0px 1px; */
}

.particles-canvas {
  z-index: -99 !important;
}

.y1-code-text {
    color: rgb(241, 139, 58);
}

.y2-code-text {
  color: rgb(200, 160, 130);
}

.r1-code-text {
  color: rgb(220, 80, 100);
}

.b1-code-text {
  color: rgb(150, 190, 190);
}

.b2-code-text {
  color: rgb(180, 210, 210);
}